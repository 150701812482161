<template>
  <div>
    <div class="mainContent">
      <a-form-model :layout="layout" style="width:100%;" ref="ruleForm" :model="form">
        
          <a-col :sm="24" :md="12" :lg="8" :xl="6">
            <a-form-model-item label="邮箱" colon :labelCol='labelcol' :wrapperCol="wrappercol" prop="email">
              <a-textarea :auto-size="{ minRows: 1 }" v-model="form.email" placeholder="请输入邮箱..." />
            </a-form-model-item>
          </a-col>
       
          <a-col :sm="24" :md="12" :lg="8" :xl="6" class="lyq_flex_grow">
            <a-form-model-item style="text-align:right;">
              
              <a-button style='margin-right:12px' type="primary" @click="search">
                搜索
              </a-button>
              <a-button  @click="reset('ruleForm')">重置</a-button>

            </a-form-model-item>
          </a-col>
      </a-form-model>
    </div>
    
  
    <div style="display:flex;align-items: flex-start;display: flex;height: 100%;">
  
      <div class="mainContent" style="width:65%;">

        <div class="tableHeader" >
          <div class="title">查询表格</div>
          <a-button type="primary"  @click="reduction" :disabled="!selectedRowKeys.length > 0">移出回收站</a-button>
        </div>

        <a-table
          :size="tableSize"
          :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange}"
          :columns="columns"
          :row-key="record => record.id"
          :data-source="data"
          :pagination="pagination"
          :loading="loading"
          bordered
          @change="handleTableChange"
          :customRow="customRow"
          :rowClassName="record => {
            if (record.id ==selectId) return 'clickRowStyle';
          }"
        >

        <!-- 邮件状态 -->
        <template slot="status" slot-scope="data,record">
              <a-tag :color="statusColor[record.status]"  style="margin-right:0">
                {{record.status|statusFilter}}
              </a-tag>
        </template>
        <!-- 订单状态 -->
        <template slot="order_status" slot-scope="data,record">
          <a-tag :color="statusColor[record.order_status]"  style="margin-right:0">
            {{record.order_status|order_statusFilter}}
          </a-tag>
        </template>
        <!-- 域名 -->
        <template slot="site_url" slot-scope="data,record">
          <a :href="'https://'+record.site_url" target="_blank"> {{record.site_url}}</a>
        </template>
        

          <!-- 操作单元格 -->
          <template slot="操作" slot-scope="data">

            <span>
              
            
              <a-popconfirm title="是否删除该条信息?" @confirm="() => onDelete(data)">
                <a-button size="small" type="danger" ghost>删除</a-button>
              </a-popconfirm>
          
            </span>

          </template>
        </a-table>
      </div>

      <div style="width:calc(35% - 12px);z-index:1001;background:#fff;padding:12px;margin-left:12px;    position: sticky;top: 76px;">
        <a-descriptions
          title=""
          bordered
          :column="{ xxl: 2, xl: 2, lg: 1, md: 1, sm: 1, xs: 1 }"
          class="lyqTableBox"
        >
          <a-descriptions-item label="客户名称">
            <div>{{emailData.name}}</div>
          </a-descriptions-item>
          <a-descriptions-item label="客户邮箱">
            <div>{{emailData.sender_email}}</div>
          </a-descriptions-item>

          <a-descriptions-item label="邮件详情">
            <div style="max-height:calc(100vh - 370px);overflow: auto;">
      
                  <div>
                    <p style="margin:6px 0 0 0px">{{emailData.email_subject}}</p>
                    <p style="margin-bottom:0">{{emailData.email_time}}</p>

                  </div>
                  <div style="padding:12px;display: block;background:#fff">
                    <a-dropdown v-if="emailData.attachment_url.length>0">
                      <a class="ant-dropdown-link" style='margin-bottom: 12px;display: block;' >
                        下载附件 <a-icon type="down" />
                      </a>
                      <a-menu slot="overlay">
                        <a-menu-item v-for="(i,z) in emailData.attachment_url" :key="z">
                          <a :href="i" target='_blank'>{{i}}</a>
                        </a-menu-item>
                      </a-menu>
                    </a-dropdown>
                    <div  v-html="emailData.message"></div>
                  </div>
          

            </div>

            
          </a-descriptions-item>


        </a-descriptions>


      </div>

    </div>

    



    
    
  

  </div>
</template>
<script>
import {getSpam_list,orderDelete,restore} from "@/axios/recycle.js"

import {getCookie} from '@/static/js/cookie';
import {myMixin} from "@/static/js/mixin.js"
import moment from 'moment';

const columns = [//列描述
{
    align:'center',
    title: 'ID',
    dataIndex: 'id',
  },

  {
    align:'center',
    title: '客户名称',
    dataIndex: 'name',
   
  },
  {
    align:'center',
    title: '发送邮箱',
    dataIndex: 'sender_email',
  },
  {
    align:'center',
    title: '接收邮箱',
    dataIndex: 'receiver_email',
  },
  {
    align:'center',
    title: '邮件日期',
    dataIndex: 'email_time',
  },
  {
    align:'center',
    title: '操作',
    // dataIndex: 'status',
    scopedSlots: { customRender: '操作', },
  },

];
export default {
  filters: {

    statusFilter(status) {
      const statusMap = {
        "0": '待关联',
        "1": '已关联',
    
      }

      return statusMap[status]

    },
    order_statusFilter(status) {
      const statusMap = {
        "2": '待处理',
        "3": '已发催单邮件',
        "4": '已发短信',
        "5": '完成',
        "6": '弃单',
      }

      return statusMap[status]

    }

  },
  mixins: [myMixin],
  data() {
    
    return {
      statusColor:{
        "0":'orange',
        "1":'green',
        "2":'orange',
        "3":'blue',
        "4":'blue',
        "5":'green',
        "6":'red',

      },
      status: [
       
        {
            value: '2',
            label: '待处理'
        },
        {
            value: '3',
            label: '已发催单邮件'
        },
        {
            value: '4',
            label: '已发短信'
        },
        {
            value: '5',
            label: '完成'
        },
        {
            value: '6',
            label: '弃单'
        },
      ],

      form: {
        page_size:50,
        page:1,
        email: '',
      },
 
      
      data: [],//数据
      pagination: {
        showQuickJumper:true,//是否快速跳转某页
        showSizeChanger:true,//是否可以改变条数
        pageSizeOptions:['10', '20', '50', '100','200'],
        pageSize:50,
        showTotal:(total, range) => `当前显示第${range[0]}-${range[1]}，总条数：${total} `
      },//分页器
      loading: false,//是否加载中
      btnLoading:false,
      columns,//列描述
      tableSize:'default',//表格尺寸

      seeModalVisible:false,//查看详情
      seeData:{},
 
      token:{
        role:''
      },
      
      
      emailData:{
        attachment_url:[],
      },//邮箱详情

      selectId:'',//选中行的ID，用来设置背景色

    }
    
  },
  created(){
    this.token=JSON.parse(getCookie("token"))
    this.fetch();
  },
  mounted() {
    
  
    
    
  },
  methods:{
 
      customRow(data){
          return {
            on: {
              click: (event,index) => {
                let no=['button','textarea','a',"input"];
                if(no.includes(event.target.localName)||data.message==null) {
                  return
                }else{
                  this.emailData={
                    attachment_url:[]
                  };
                  this.$nextTick(() => { // 先等dom更新后，再重新赋值，不会有缓存
                    this.emailData=data;
                    this.selectId=data.id
                  });                          
                }
                
              },        // 点击表头行
            }
          };

      },

      changeStatus(data){
        this.form.page=1;
        this.pagination.current =1;
        this.fetch();
      },
      async fetch (params = {}) {
        this.loading = true;
        let data=await getSpam_list({...params,...this.form})

        const pagination = { ...this.pagination };
        pagination.total = data.data.total;
        this.data = data.data.list;
        this.pagination = pagination;
        console.log(this.data,data,'data数据')
        this.loading = false; 
        this.selectedRowKeys=[];
        this.emailData={
          attachment_url:[]
        };
        this.selectId='';
      },
      //删除
      async onDelete(data){
        let resule=await orderDelete({id:data.id});
          if(resule.code==200){
            this.$message.success(resule.msg);
            this.fetch()
          }else{
            this.$message.error(resule.msg);
        }
      },
     
      // 移除回收站
      reduction(){
        let ids=this.selectedRowKeys.join(',')
        restore({ids:ids}).then((resule)=>{
          if(resule.code==200){
            this.$message.success(resule.msg);
            this.fetch()
          }else{
            this.$message.error(resule.msg);
        }
        });
          
        console.log('要移除的id是',ids)
      },
      

      shopEmail(){
        // // this.activeKey==!this.activeKey
        // console.log(this.activeKey)
      },

    }
};
</script>
<style lang="scss" scoped>
@import "@/static/css/index.scss";
.ant-table td { white-space: nowrap; }
.ant-table td .greenBtn{background-color:#7ac141;border-color:#7ac141;}
::v-deep .ant-descriptions-item-label {
    width: 100px;
    padding: 10px 16px;
    vertical-align: baseline;
}
::v-deep .mainContent .ant-form .ant-form-item .ant-btn {
  margin-left: -1px !important;
  width:auto;
}

 .leftModal ::v-deep .ant-modal{margin: 0 auto 0 5% !important;width: 60% !important;}
 .lyq_top_border{
  padding:12px
 }

 ::v-deep .ant-descriptions-item-content{word-break: break-all;  padding:12px;  max-height: 400px;overflow-y: auto;}
 ::v-deep .ant-collapse-content > .ant-collapse-content-box{padding:0;}
 ::v-deep .ant-collapse-content p,.ant-collapse-header p{margin-bottom:0px;}
 ::v-deep .ant-table-tbody > tr > td{padding:8px;}

 ::v-deep .lyqTableBox.ant-descriptions-bordered .ant-descriptions-view > table {
    table-layout: fixed;
}
</style>