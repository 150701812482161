
import {get,post} from "@/axios";


export function getSpam_list(data) {
  return get('/api/email_list/spam_list',data);
}

export function restore(data) {
  return post('/api/email_list/restore',data);
}

export function orderDelete(data) {
  return post('/api/email_list/delete',data);
}
